<template>

    <v-card class="eventCard elevation-1" :class="[{'finished':eventData.state=='finished'},{'enrolled':enrolled},{'missingEval':enrolled && missingEval && eventData.state=='finished'}]">
        <div class="eventCard-content">
            <v-card-text class="pa-0">
                <v-container style="width: 380px; margin-left: 8px;">
                    <v-row align="center" v-if="debug || !(options.hide && options.hide.includes('program-title'))">
                        <v-col cols="1">
                            <div class="event-detail-icon">
                                <v-icon>mdi-camera-enhance-outline</v-icon>
                            </div>
                        </v-col>
                        <v-col cols="11" class="program-title column-text">
                            <div class="clamped" v-html="program.title[language]"/>
                            <div v-if="debug" class="debug">PID: {{eventData.program_id}}</div>
                        </v-col>
                    </v-row>

                    <v-row align="center" v-if="debug || !(options.hide && options.hide.includes('event-title'))">
                        <v-col cols="1">
                            <div class="event-detail-icon">
                                <v-icon>mdi-lightbulb-on-outline</v-icon>
                            </div>
                        </v-col>
                        <v-col cols="11" class="event-title column-text">
                            <div class="clamped" v-html="eventData.title[language]"/>
                            <div v-if="debug" class="debug">ID: {{eventData.id}} | Lang: {{eventData.language}}</div>
                        </v-col>
                    </v-row>

                    <v-row align="center">
                        <v-col cols="1">
                            <div class="event-detail-icon">
                                <v-icon>mdi-calendar-month</v-icon>
                            </div>
                        </v-col>
                        <v-col cols="11" class="event-date column-text">
                            <div>{{eventStart}}</div>
                            <div class="duration">
                                <str index="events.card.duration"/>:
                                <span v-if="timeUntil(eventData.start_time, eventData.end_time).hours>0">
                                    {{timeUntil(eventData.start_time, eventData.end_time).hours}} <str index="events.card.hour"/>(s)
                                </span>
                                {{timeUntil(eventData.start_time, eventData.end_time).minutes}} <str index="events.card.minute"/>(s)
                            </div>
                            <div v-if="debug" class="debug">
                                <div v-if="!['started','finished'].includes(eventData.state)">
                                    Time until start: {{timeLeft.days}}d {{timeLeft.hours}}h {{timeLeft.minutes}}m {{timeLeft.seconds}}s
                                </div>
                                <div v-if="eventData.state=='started'">
                                    Time to finish: {{timeFinished.minutes}}m {{timeFinished.seconds}}s
                                </div>
                            </div>
                        </v-col>
                    </v-row>

                    <v-row align="center" v-if="eventData.speakers.length>0">
                        <v-col cols="1">
                            <div class="event-detail-icon">
                                <v-icon>mdi-account-voice</v-icon>
                            </div>
                        </v-col>
                        <v-col cols="11" class="event-detail-speaker column-text">
                            <div>
                                <Str index="events.card.speaker" :language="language"/>
                            </div>
                        </v-col>
                    </v-row>
                </v-container>

                <div class="speaker-container">
                    <template v-if="eventData.speakers.length<=3">
                        <template v-for="(speakerID) in eventData.speakers">
                            <SpeakerAvatar :enrolled="enrolled" :speaker="speakers[speakerID]" :language="language" :key="`event_${eventData.id}_speaker_${speakerID}`"/>
                            <!-- <div class="speaker-member" align="center" v-if="(eventData.speakers.length<=3) || (eventData.speakers.length>3 && i<2)" :key="'speaker_member_'+speakerID">
                                <v-avatar size="80">
                                    <v-icon v-if="speakers[speakerID].image.type=='icon'" large>{{speakers[speakerID].image.value}}</v-icon>
                                    <img v-if="speakers[speakerID].image.type=='img'" :src="images.speakers[speakers[speakerID].image.value]"/>
                                </v-avatar>
                                <div class="speaker-credentials">
                                    {{speakers[speakerID].given_name}} {{speakers[speakerID].family_name}}<br>
                                    {{speakers[speakerID].credentials[language]}}
                                </div>
                            </div>
                            <div class="speaker-member" align="center" v-else-if="i==2" :key="'speaker_member_'+speakerID">
                                <v-avatar size="80">
                                    <v-icon large>mdi-account-group-outline</v-icon>
                                </v-avatar>
                                <v-btn text @click="moreDetails" class="speaker-credentials">...more</v-btn>
                            </div> -->

                        </template>
                    </template>
                    <template v-else>
                        <template v-for="(speakerID, i) in eventData.speakers">
                            <SpeakerAvatar v-if="i<2" :enrolled="enrolled" :speaker="speakers[speakerID]" :language="language" :key="`event_${eventData.id}_speaker_${speakerID}`"/>
                        </template>
                        <div>
                            <v-avatar size="90">
                                <v-icon large>
                                    mdi-account-group-outline
                                </v-icon>
                            </v-avatar>
                            <div>
                                <v-btn text @click="moreDetails">
                                    <str index="events.buttons.more_speakers" :style="{'color':!enrolled ? '#00709E':''}"/>
                                </v-btn>
                            </div>
                        </div>
                    </template>
                </div>



                <div class="programDecal">
                    <div class="programDecal-container">
                        <div class="programDecal-front-fold"/>
                        <div class="programDecal-back-fold"/>
                        <v-icon color="white" style="position: relative; left: 50%; top: 50%; transform: translate(-50%,-50%)">
                            {{eventData.icon.value}}
                        </v-icon>
                    </div>

                    <div v-if="eventData.state!='finished'" class="event-eventData.state" style="border: 1px solid grey;text-align: center;background-color: white; font-size: 8pt; padding: 5px 0px 5px 0px;">
                        <template v-if="eventData.state=='days_away'">
                            <!-- <v-icon small  color="green">mdi-calendar-clock-outline</v-icon> -->
                            <div style="margin: 8px 0px 8px 0px; line-height: 15px;"><str index="events.card.live_in"/><br>{{timeLeft.days}} <str index="events.card.day"/>(s)</div>
                        </template>

                        <template v-else-if="eventData.state=='hours_away'">
                            <!-- <v-icon small  color="green">mdi-timer-sand</v-icon> -->
                            <div style="margin: 8px 0px 8px 0px; line-height: 15px;"><str index="events.card.live_in"/><br>{{timeLeft.hours}} <str index="events.card.hr"/>(s)</div>
                        </template>

                        <template v-else-if="eventData.state=='minutes_away'">
                            <!-- <v-icon :color="colorWheel">mdi-motion-play-outline</v-icon> -->
                            <span style="margin: 8px 0px 8px 0px; line-height: 15px;">
                                <template v-if="timeLeft.minutes==0">
                                    <str index="events.card.starting"/>
                                </template>
                                <template v-else>
                                    <str index="events.card.live_in"/><br>{{timeLeft.minutes}} <str index="events.card.min"/>
                                </template>
                            </span>
                        </template>

                        <template v-else-if="eventData.state=='started'">
                            <v-icon :color="colorWheel">mdi-radio-tower</v-icon>
                            <br>
                            <span style="margin: 8px 0px 8px 0px; line-height: 15px;">{{timeFinished.minutes}}m {{timeFinished.seconds}}s</span>
                        </template>

                        <v-icon small v-else color="grey">mdi-timer-sand-complete</v-icon>
                    </div>

                    <div v-if="debug" class="debug">{{eventData.state}}</div>
                </div>


            </v-card-text>

            <div v-if="!enrolled" class="separator"></div>
            <!-- :style="{'background-color': enrolled && eventData.state!='finished' ? '#CCE6D4' : ''}" -->
            <div style="z-index: 1;">
                <EventActions
                    :eventData="eventData"
                    :debug="debug"
                    :state="state"
                    :enrolled="enrolled"
                    :buttons="buttons"
                    :language="language"
                    :bookings="bookings"
                    :options="options"
                    @enroll="enroll"
                    @more_details="moreDetails"
                >
                    <template v-slot:actionButtons>
                        <slot name="actionButtons"/>
                    </template>
                </EventActions>
            </div>
             <!-- :style="{'background-color': eventData.state!='finished' ? '#BFD8C8' : ''}" -->
            <div v-if="enrolled" style="z-index: 1; text-align: center; ">
                <EventResources :eventData="eventData" :enrolled="enrolled" :buttons="buttons" :missingEval="missingEval" :language="language" @withdraw="withdraw">
                    <template v-slot:resourceButtons>
                        <slot name="resourceButtons"/>
                    </template>
                </EventResources>
            </div>
        </div>
    </v-card>

</template>

<script>
import EventActions from "./EventActions.vue"
import EventResources from "./EventResources.vue"
import SpeakerAvatar from "./SpeakerAvatar.vue"

export default {
    name: "EventCard",
    components: {
        EventActions,
        EventResources,
        SpeakerAvatar
    },
    props: {
        debug: {
            type: Boolean,
            required: false,
            default: false
        },
        language: {
            type: String,
            required: true
        },
        program: {
            type: Object,
            required: true
        },
        speakers: {
            type: Object,
            required: true
        },
        enrolled: {
            type: Boolean,
            required: true
        },
        enrollmentRecord: {
            type: Object,
            required: false
        },
        missingEval: {
            type: Boolean,
            required: false,
            default: false
        },
        buttons: {
            type: Object,
            required: false,
            default: ()=>{return {}}
        },
        options: {
            type: Object,
            required: false,
            default: ()=>{return {}}
        },
        bookings: {
            type: Number,
            required: true
        }
    },
    created: function(){
        this.init()
    },
    data: function(){
        return {
            ui: {
                confirmWithdraw: false
            },
            timers: {
                timeLeft: null,
                timeFinished: null,
                colorWheel: null
            },
            timeLeft: {},
            timeFinished: {},
            colorWheel: 'red',
            // colorsArray: ['#ff0000','#ff5400']
            colorsArray: ['red','orange']
        }
    },
    methods: {
        init: function(){

            let self = this
            let today = new Date()
            clearInterval(this.timers.timeLeft)
            clearInterval(this.timers.timeFinished)

            let untilStart = this.timeUntil(today.getTime(), this.eventData.start_time)
            let untilEnd = this.timeUntil(today.getTime(), this.eventData.end_time)

            if(untilStart!=false){

                if(untilStart.days>0){
                    this.eventData.state = 'days_away'
                    this.timeLeft = untilStart
                    this.timers.timeLeft = setInterval(() => {
                        let today = new Date()
                        self.timeLeft = self.timeUntil(today.getTime(), self.eventData.start_time)
                        if(self.timeLeft===false){self.init()}
                    }, (1000));//1000*60*60

                }else{
                    if(untilStart.hours>=1){
                        this.eventData.state = 'hours_away'
                        this.timeLeft = untilStart
                        this.timers.timeLeft = setInterval(() => {
                            let today = new Date()
                            self.timeLeft = self.timeUntil(today.getTime(), self.eventData.start_time)
                            if(self.timeLeft===false){self.init()}
                        }, (1000));//1000*60
                    }else{
                        this.eventData.state = 'minutes_away'
                        this.timeLeft = untilStart
                        this.timers.timeLeft = setInterval(() => {
                            let today = new Date()
                            self.timeLeft = self.timeUntil(today.getTime(), self.eventData.start_time)
                            if(self.timeLeft===false){self.init()}
                        }, 1000);
                    }
                }

            }else if(untilEnd!=false){
                this.eventData.state = 'started'
                this.timers.timeFinished = setInterval(() => {
                    let today = new Date()
                    self.timeFinished = self.timeUntil(today.getTime(), self.eventData.end_time)
                    if(self.timeFinished===false){self.init()}
                }, 1000);
            }else{
                this.eventData.state = 'finished'
            }
            this.$emit('initialized',self.eventData.id)
        },
        allTrue: function(object){
            for(let key in object){
                let result = object[key]
                if(!result){
                    return false
                }
            }
            return true
        },
        enroll: function(){
            this.$emit('enroll',this.eventData)
        },
        withdraw: function(){
            this.$emit('withdraw',this.eventData)
        },
        moreDetails: function(){
            this.$emit('more_details',this.eventData)
        },
        timeUntil: function(t1, t2){
            let a = new Date(t1)
            let b = new Date(t2)
            let diff = b - a

            if(diff>=0){
                let days = Math.floor(diff/1000/60/60/24)
                let hours = Math.floor((diff - (days*24*60*60*1000)) / 1000/60/60)
                let minutes = Math.floor((diff - (days*24*60*60*1000) - (hours*60*60*1000)) /1000/60)
                let seconds = Math.floor((diff - (days*24*60*60*1000) - (hours*60*60*1000) - (minutes*60*1000)) /1000)

                return {
                    days,
                    hours,
                    minutes,
                    seconds
                }
            }else{
                return false
            }
        },
        // spinColorWheel: function(){
        //     let self = this
        //     clearInterval(this.timers.colorWheel)

        //     this.timers.colorWheel = setInterval(function(){
        //         if(self.state=='started'){
        //             let indexLimit = self.colorsArray.length
        //             let index = self.colorsArray.indexOf(self.colorWheel)+1 == indexLimit ? self.colorsArray.indexOf(self.colorWheel)+1 : 0
        //             self.colorWheel = self.colorsArray[index]
        //             self.spinColorWheel()
        //         }

        //     },3000)
        // }
    },
    computed: {
        eventData: function(){
            return this.$attrs.value
        },
        eventStart: function(){
            let d = new Date(this.eventData.start_time)
            let output = d.toDateString()+' '+d.toLocaleTimeString()
            this.eventData.eventStart = output
            return output
        },
        eventEnd: function(){
            let d = new Date(this.eventData.end_time)
            return d.toString()
        },
        hoursPercentLeft: function(){
            let timeLeft = this.timeLeft
            return Math.floor(((((timeLeft.minutes*60) + timeLeft.seconds)/60) / 60)*100)
        },
        state: function(){
            return this.eventData.state
        }
    },
    watch: {
        state: function(){
            // this.spinColorWheel(this.colorsArray)
            // this.$emit(this.state, this.eventData)
            // this.$emit('input',this.state)
            // if(this.enrolled && this.state=='minutes_away'){
            //     this.$emit('starting',this.eventData)
            // }
            // if(this.enrolled && this.state=='started'){
            //     this.$emit('started',this.eventData)
            // }
        }
    }
}
</script>

<style scoped>
</style>

<style scoped lang="scss">
.eventCard{
    display: inline-block;
    // width: 350px;
    max-width: 528px;
    min-height: 550px;
    margin: 15px;
    border-radius: 0px;
    background-color: $primary-light-1;
    padding-left: 12px;
}

.enrolled.eventCard{
    background-color: #1AA323;
    border: 1px solid #1AA323;
}

.finished.eventCard{
    background-color: $primary-light-3;
}

.enrolled.finished.eventCard{
    // background-color: $primary-light-2;
    background-color: $secondary-faded;
}

.enrolled.finished.missingEval.eventCard{
    background-color: rgb(255, 215, 0);
}

.eventCard-content{
    text-align: left;
    padding: 25px 0px 0px 0px;
    background-color: $primary-light-3;
    height:100%;
    min-height: 498px;

    .container {
        @media(max-width:450px){
            width: 80% !important;
        }
    }
}

.decoration-column{
    width: 50px;
    height: 100%;
    background-color: $primary-light-1;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 0;
}



.program-title{
    font-size: 18pt;
    font-weight: bold;
}
.event-title{
    font-size: 18px;
    font-weight: 500;
}


.separator{
    border-top: 1px solid $primary;
    width: 100%;
    margin: 15px 0px 15px 0px;
}


.event-detail-icon{
    background-color: $primary-light-1;
    width: 33px;
    height: 33px;
    border-radius: 50%;
    z-index: 2;
}


.event-detail-icon > * {
    color: white;
    font-size: 1.5em;
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-60%);
    z-index: 2;
}

.speaker-container{
    width: 80%;
    margin: 0 auto;
    margin-top: 10px;
    text-align: center;
    display: flex;
    // justify-content: space-evenly;
    justify-content: space-around;
    flex-wrap: wrap;
}

.speaker-container .v-btn{
    color: $secondary;
    font-weight: bold;
}

.speaker-member{
    width: 30%;
}

.speaker-credentials{
    // text-align: left;
    margin: 10px 0px 25px 0px;
    font-size: 0.8em;
    line-height: 15px;
}

.speaker-container .v-avatar{
    border: 5px solid $primary;
    background-color: $primary-light-4 !important;
    color: white;
    margin: 0px 15px 0px 15px;
}
    .enrolled .speaker-container .v-avatar{
        border: 5px solid $secondary;
    }


.column-text{
    padding-left: 25px;
}

.clamped{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.programDecal{
    position: absolute;
    top: 0px;
    right: 25px;
}

.programDecal-container{
    position: relative;
    background-color: $event-cta-1;
    width: 50px;
    height: 60px;
}

.programDecal-back-fold{
    position: absolute;
    top: -8px;
    right: -6px;
    width: 10px;
    height: 8px;
    background-color: $event-cta-2;
    z-index: 1;
}

.programDecal-front-fold{
    position: absolute;
    top: -8px;
    right: -3px;
    transform: skewX(-40deg);
    width: 100%;
    height: 8px;
    background-color: $event-cta-1;
    z-index: 2;
}

.enrolled .programDecal-container, .enrolled .programDecal-front-fold{
    background-color: $event-cta-1;
}
.enrolled .programDecal-back-fold{
    background-color: $event-cta-2;
}

.event-state-timer{
  -webkit-transition: all 3s ease-out;
  -moz-transition: all 3s ease-out;
  -o-transition: all 3s ease-out;
  transition: all 3s ease-out;
}

.event-date{
    color: $primary-light-1;
    font-size: 16px;

    .duration {
        font-size: 14px;
        color: #000000;
        font-weight: 500;
    }
}

.event-detail-speaker{
    font-size: 16px;
}

.finished .eventCard-content{
    background-color: $grey-light-1;
}

.enrolled-info{
    z-index: 1;
    text-align: right;
    position: absolute;
    bottom: -35px;
    right: 0px;
    width: 100%;
}

@media only screen and (max-width: 800px){
    .eventCard{
        padding: 0px;
    }

    .eventCard-content .row {
        flex-wrap: nowrap;

        > .col {
            @media(max-width: 450px){
                padding-left: 0;
                margin-right: 24px;
            }
        }

        .event-title {
            > div {
                display: block;
            }
        }
    }

    .programDecal{
        right: 6px;
    }

    .programDecal-container{
        height: 35px;
    }
}
</style>
