<template>
<div>
    <UserBadge v-if="badge && signedIn" :user="$store.getters.user.attributes">
        <template v-slot:buttons>
            <v-btn
                @click="ui.popups.editProfile = !ui.popups.editProfile"
                depressed
                rounded
                text
            >
                <str index="authenticator.buttons.edit_profile"/>
            </v-btn>
            <v-divider class="my-3"></v-divider>

            <template v-if="contains(user.groups,['SuperAdmin','staff'])">
                <v-switch :true-value="true" :false-value="false" v-model="ui.exposeLabels" label="Edit Labels Onscreen" @change="exposeLabels"/>
                <v-btn text rounded @click="$router.push({name:'newLabel'})">Label Editor</v-btn>
                <v-divider class="my-3"></v-divider>

                <v-btn text rounded @click="$router.push({name:'eventAdmin'})">Event Admin</v-btn>
                <v-divider class="my-3"></v-divider>
            </template>

            <template v-else-if="contains(user.groups,['tester'])">
                <v-switch :true-value="true" :false-value="false" v-model="ui.exposeLabels" label="Edit Labels Onscreen" @change="exposeLabels"/>
                <v-btn text rounded @click="$router.push({name:'newLabel'})">Label Editor</v-btn>
                <v-divider class="my-3"></v-divider>
            </template>

            <template v-else-if="contains(user.groups,['hygienist'])">
                <v-btn text rounded @click="$router.push({name:'eventAdmin'})">Event Admin</v-btn>
                <v-divider class="my-3"></v-divider>
            </template>

            <template v-if="contains(user.groups,['SuperAdmin','staff', 'developer', 'tester', 'client'])">
                <v-btn text @click="$router.push({name: 'Dashboard'})">
                    <!-- Dashboard -->
                    <str index="authenticator.buttons.dashboard"/>
                </v-btn>
                <v-divider class="my-3"></v-divider>
            </template>

            <template v-if="contains(user.groups,['SuperAdmin','staff', 'developer', 'tester'])">
                <v-btn text @click="$router.push({name: 'DataDump'})">
                    <!-- Sample Requests -->
                    <!-- <str index="authenticator.buttons.sample_requests"/> -->
                    Data Dump
                </v-btn>
                <v-divider class="my-3"></v-divider>
            </template>

            <template v-if="contains(user.groups,['SuperAdmin','staff','tester'])">
                <v-switch v-if="debug" :true-value="true" :false-value="false" v-model="ui.popups.moreInfo" label="More Profile Info"/>
                <v-btn depressed rounded @click="$store.commit('debug',!$store.getters.debug)">
                    <v-icon left :color="$store.getters.debug ? 'red' : 'grey'">mdi-bug-outline</v-icon> Debug
                </v-btn>
                <v-divider class="my-3"></v-divider>
            </template>

            <template v-if="debug">
                <v-btn depressed text>
                    Expiry: {{signedIn ? session.time_left : ''}}
                </v-btn>
                <v-btn depressed text>
                    Inactivity: {{$store.getters.inactivity}}
                </v-btn>
            </template>
            <v-btn
                @click="signOut"
                depressed
                rounded
                text
            >
                <str index="authenticator.buttons.sign_out"/>
            </v-btn>
        </template>
    </UserBadge>
    <!-- <v-btn text v-if="buttons.signOut && signedIn" @click="signOut">
        <v-icon small left>mdi-exit-run</v-icon>
        Sign Out
    </v-btn> -->



    <template v-if="!buttons.signOut">
        <template v-if="!signedIn">
            <div class="signIn-view">
                <v-toolbar flat class="tabContainer">
                    <v-tabs v-model="ui.view" ref="tabs" centered :hide-slider="false">
                        <v-tab :href="'#SignIn'">
                            <span class="tab-text">
                                <str index="authenticator.view.sign_in.tab"/>
                            </span>
                        </v-tab>
                        <v-tab :href="'#SignUp'">
                            <span class="tab-text">
                                <str index="authenticator.view.sign_up.tab"/>
                            </span>
                        </v-tab>
                        <v-tabs-slider class="tab-slider"></v-tabs-slider>
                    </v-tabs>
                </v-toolbar>

                <!-- <div v-if="options && options.social" style="margin: 40px;" >
                    <p>Sign {{ui.view=='SignIn' ? 'in' : 'up'}} with your social media account</p>
                    <v-btn v-if="options.social.Facebook" fab class="elevation-0" style="margin: 15px;" color="white" @click="federation('Facebook')">
                        <v-avatar>
                            <img :src="images.Facebook"/>
                        </v-avatar>
                    </v-btn>

                    <v-btn v-if="options.social.LinkedIn" fab class="elevation-0" style="margin: 15px;" color="white" @click="federation('LinkedIn')">
                        <v-avatar>
                            <img :src="images.LinkedIn"/>
                        </v-avatar>
                    </v-btn>

                    <v-btn v-if="options.social.Twitter" fab class="elevation-0" style="margin: 15px;" color="white" @click="federation('Twitter')">
                        <v-avatar>
                            <img :src="images.Twitter"/>
                        </v-avatar>
                    </v-btn>

                    <v-btn v-if="options.social.Google" fab class="elevation-0" style="margin: 15px;" color="white" @click="federation('Google')">
                        <v-avatar>
                            <img :src="images.Google"/>
                        </v-avatar>
                    </v-btn>

                    <div style="margin: 25px; position: relative;">
                        <div style="z-index: 1; position: relative; border-top: 1px solid lightgrey; top: 25px; width: 100%;"/>
                        <div style="z-index: 5; position: relative; background-color: white; margin: 0 auto; padding-top: 10px; border: 1px solid lightgrey; border-radius: 50%; text-align: center; font-weight: bold; width: 50px; height: 50px;">
                            or
                        </div>
                    </div>
                </div> -->



                <v-tabs-items v-model="ui.view">
                    <v-tab-item :value="'SignIn'">
                        <div style="max-width: 400px; margin: 0 auto; margin-top: 30px; margin-bottom: 30px;">

                            <div v-if="ui.flow=='password'">

                                <v-text-field v-model="form.signIn.email"
                                    ref="sign_in_email"
                                    prepend-inner-icon="mdi-account-circle"
                                    outlined
                                    required
                                    @keydown="ui.remember_me=false;rememberMe()"
                                    :rules="rules.validEmail"
                                    :label="labels.forms.email._text[language]"
                                    :rounded="false"
                                    persistent-placeholder
                                />

                                <v-text-field v-model="form.signIn.password"
                                    prepend-inner-icon="mdi-lock"
                                    :append-icon="ui.showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                                    @click:append="ui.showPassword = !ui.showPassword"
                                    outlined
                                    :type="ui.showPassword ? 'text' : 'password'"
                                    :rules="rules.required"
                                    :label="labels.forms.password._text[language]"
                                    :rounded="false"
                                    @keydown.enter="signIn('password')"
                                    persistent-placeholder                                   
                                />

                                <div>
                                    <v-btn text class="text-decoration-underline" @click="ui.flow='forgotPassword'"><str index="authenticator.view.sign_in.forgot_password"/></v-btn>
                                </div>

                                <div>
                                    <v-checkbox :disabled="!validations.validEmail(form.signIn.email)" v-model="ui.remember_me" :true-value="true" :false-value="false" @change="rememberMe" style="position: relative; left: 50%; transform: translate(-15%,0%)">
                                        <template v-slot:label>
                                            <str index="authenticator.view.sign_in.remember_me"/>
                                        </template>
                                    </v-checkbox>
                                </div>

                                <v-btn color="primary" class="theme" :rounded="false" :disabled="!allowSignIn" @click="signIn('password')">
                                    <v-icon left v-if="ui.state=='sending'">mdi-spin mdi-loading</v-icon>
                                    <str index="authenticator.buttons.sign_in"/>
                                </v-btn>

                                <or/>

                                <v-btn color="primary" class="theme" :rounded="false" @click="ui.flow='pin'">
                                    <str index="authenticator.buttons.one_time_pin"/>
                                </v-btn>
                            </div>


                            <div v-if="ui.flow=='forgotPassword'">
                                <v-btn text @click="ui.flow='password'" style="position: absolute; top: 5px; left: 5px;"><v-icon>mdi-chevron-left</v-icon> <str index="buttons.back"/></v-btn>

                                <h3>
                                    <str index="authenticator.view.forgot_password.header"/>
                                </h3>
                                <v-form ref="forgotPassword" v-model="validForms.forgotPassword">
                                    <div  style="margin: 25px 0px 15px 0px;">
                                        <str index="authenticator.view.forgot_password.subheader"/>
                                    </div>
                                    <v-text-field v-model="form.signIn.email"
                                        prepend-inner-icon="mdi-account-circle"
                                        outlined
                                        required
                                        :rules="rules.validEmail"
                                        :label="labels.forms.email._text[language]"
                                        :rounded="false"
                                        :append-icon="validations.validEmail(form.signIn.email) ? 'mdi-send' : ''"
                                        @click:append="forgotPassword('generateResetCode')"
                                    />

                                    <v-container>
                                        <v-row>
                                            <v-col>
                                                <str index="authenticator.view.forgot_password.enter_reset_pin"/>
                                                <v-otp-input
                                                    v-model="form.forgotPassword.pin"
                                                    type="number"
                                                    :length="config.pinLength"
                                                />
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col>
                                                <v-text-field v-model="password.new" :disabled="!allowChangePassword" :type="ui.showPassword ? 'text' : 'password'" outlined :rounded="false" :label="labels.forms.password._text[language]" prepend-inner-icon="mdi-lock" :append-icon="ui.showPassword ? 'mdi-eye-off' : 'mdi-eye'" @click:append="ui.showPassword = !ui.showPassword" required :rules="rules.validPassword"><!--@keyup="$refs.forgotPassword.validate()" :rules="rules.verifyPasswords"-->
                                                    <template v-slot:message="{message, key}">
                                                        <span v-html="message"/>
                                                    </template>
                                                </v-text-field>
                                                <v-text-field v-model="password.confirmation" :disabled="!allowChangePassword" :type="ui.showPassword ? 'text' : 'password'" outlined :rounded="false" :label="labels.forms.confirm_password._text[language]" prepend-inner-icon="mdi-lock" :append-icon="ui.showPassword ? 'mdi-eye-off' : 'mdi-eye'" @click:append="ui.showPassword = !ui.showPassword" required :rules="rules.verifyPasswords"/><!--@keyup="$refs.forgotPassword.validate()" :rules="rules.verifyPasswords"-->
                                                <v-btn color="primary" :rounded="false" :disabled="!validForms.forgotPassword" @click="forgotPassword('tryResetCode')">
                                                    <str index="buttons.submit"/>
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col>
                                            </v-col>
                                        </v-row>
                                    </v-container>

                                </v-form>

                            </div>


                            <v-container v-if="ui.flow=='pin'">
                                <div style="margin: 15px;">
                                    <str index="authenticator.view.pin.subheader"/>
                                </div>
                                <v-text-field v-model="form.signIn.email"
                                    prepend-inner-icon="mdi-account-circle"
                                    outlined
                                    required
                                    :rules="rules.validEmail"
                                    :label="'Email'"
                                    :rounded="false"
                                    :append-icon="validations.validEmail(form.signIn.email) ? 'mdi-send' : ''"
                                    @click:append="requestPIN"
                                />

                                <v-row>
                                    <v-col style="text-align: right;">

                                        <center>
                                            <str index="authenticator.view.pin.enter_pin"/>
                                        </center>
                                        <v-otp-input
                                            v-model="form.signIn.pin"
                                            type="number"
                                            :length="config.pinLength"
                                        />
                                        <v-btn text v-if="temp.user" :disabled="!validations.validEmail(form.signIn.email)" @click="requestPIN">
                                            <str index="authenticator.buttons.resend"/>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-btn color="primary" :rounded="false" :disabled="!allowPIN && !temp.user" @click="signIn('pin')">
                                            <str index="authenticator.buttons.sign_in"/>
                                        </v-btn>
                                    </v-col>
                                </v-row>

                                <or/>

                                <v-btn color="primary" :rounded="false" dark @click="ui.flow='password'">
                                     <str index="authenticator.buttons.use_password"/>
                                </v-btn>
                            </v-container>

                        </div>
                    </v-tab-item>

                    <v-tab-item value="SignUp">
                        <div style="max-width: 400px; margin: 0 auto; margin-top: 30px;">
                            <v-form ref="signUp" v-model="validForms.signUp">
                                <v-text-field v-model="form.signUp.email" outlined :rounded="false" :label="labels.forms.email._text[language]" required :rules="rules.validEmail" prepend-inner-icon="mdi-account-circle"/>
                                <v-text-field v-model="password.new" :type="ui.showPassword ? 'text' : 'password'" outlined :rounded="false" :label="labels.forms.password._text[language]" prepend-inner-icon="mdi-lock" :append-icon="ui.showPassword ? 'mdi-eye-off' : 'mdi-eye'" @click:append="ui.showPassword = !ui.showPassword" required :rules="rules.validPassword">
                                    <template v-slot:message="{ message }">
                                        <str :index="message" />
                                    </template>
                                </v-text-field>
                                <v-text-field v-model="password.confirmation" :type="ui.showPassword ? 'text' : 'password'" outlined :rounded="false" :label="labels.forms.confirm_password._text[language]" prepend-inner-icon="mdi-lock" :append-icon="ui.showPassword ? 'mdi-eye-off' : 'mdi-eye'" @click:append="ui.showPassword = !ui.showPassword" required :rules="rules.verifyPasswords"/>

                                <div class="chkbx-wrapper">
                                    <v-checkbox v-model="form.signUp.ctc_casl" :true-value="'true'" :false-value="'false'" class="mt-0">
                                        <template slot="label">
                                            <div>
                                                <str index="authenticator.view.sign_up.casl"/>
                                            </div>
                                        </template>
                                    </v-checkbox>
                                </div>
                                <div class="chkbx-wrapper">
                                    <v-checkbox v-model="form.signUp.terms" :true-value="true" :false-value="false" required :rules="rules.required" @change="$refs.signUp.validate();" class="mt-0">
                                        <template slot="label">
                                            <str index="authenticator.view.sign_up.terms"/>
                                        </template>
                                    </v-checkbox>
                                </div>
                                <div class="chkbx-wrapper">
                                    <v-checkbox v-model="form.signUp.sample_consent" :true-value="true" :false-value="false" class="mt-0">
                                        <template slot="label">
                                            <div>
                                                <str index="authenticator.view.sign_up.sample_consent_prt1"/>
                                                <a :href='kenvuePrivacyPolicyLink' target='_blank' @click='handleLinkCheckboxClick($event)'>
                                                    {{$store.getters.labels['authenticator']['view']['sign_up']['privacy_policy_title']['_text'][language]}}
                                                </a>
                                                <str index="authenticator.view.sign_up.sample_consent_prt2"/>
                                            </div>
                                        </template>
                                    </v-checkbox>
                                </div>
                                
                                <v-btn color="primary" :disabled="!validForms.signUp" class="theme" @click="signUp">
                                    <str index="authenticator.buttons.sign_up"/>
                                </v-btn>

                                <!-- <or/>

                                <h3>
                                    <str index="authenticator.buttons.resume_sign_up"/>
                                </h3>
                                <v-text-field v-model="form.signUp.email" outlined :rounded="false" :label="labels.forms.email._text[language]" required :rules="rules.validEmail" prepend-inner-icon="mdi-account-circle"/>
                                <v-btn class="theme" color="primary" :disabled="!username || username.length<5" @click="ui.confirmSignUp=true">
                                    <str index="authenticator.buttons.enter_verification_code"/>
                                </v-btn> -->
                            </v-form>
                        </div>
                    </v-tab-item>
                </v-tabs-items>
            </div>
        </template>



        <div v-if="ui.message && typeof ui.message=='string'">
            {{ui.message}}
        </div>
        <!-- <json-viewer v-if="ui.error!=null && typeof ui.error=='object'" :value="ui.error"></json-viewer> -->
    </template>

    <v-dialog v-model="ui.confirmSignUp" :persistent="true" width="400">
        <v-card>

            <v-btn icon style="position:absolute; top:0px; right:0px;" @click="ui.confirmSignUp=false">
                <v-icon>mdi-close-circle</v-icon>
            </v-btn>
            <v-card-title>
                <str index="authenticator.view.sign_up.text.verify_account"/>
            </v-card-title>
            <v-card-subtitle>{{username}}</v-card-subtitle>
            <v-card-text>
                <!-- <v-otp-input
                    v-model="form.verificationCode"
                    type="number"
                    :length="config.pinLength"
                /> -->
                <str :index="ui.error==='UserNotConfirmedException' ?  'authenticator.messages.user_not_confirmed' : 'authenticator.messages.validation_email_sent'"/>
            </v-card-text>
            <v-card-actions>
                <v-spacer/>
                <v-btn color="primary" class="theme" :disabled="!username || username.length<5" @click="resendConfirmationCode">
                    <str index="authenticator.buttons.resend"/>
                </v-btn>
                    <!-- <v-btn color="primary" class="theme" :disabled="form.verificationCode==null || form.verificationCode.length!=config.pinLength" @click="confirmSignUp">
                        <str index="authenticator.buttons.verify"/>
                    </v-btn> -->
                <v-btn color="primary" class="theme" @click="ui.confirmSignUp=false">
                    <str index="buttons.close"/>
                </v-btn>
                <v-spacer/>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-dialog v-model="ui.popups.editProfile" persistent width="800">
        <v-card elevation='0'>
            <v-card-text>
                <EditProfile v-if="ui.popups.editProfile"
                    v-model="form.update"
                    :config="config"
                    :labels="labels"
                    :language="language"
                    :validations="validations"
                    :rules="rules"
                    @updateProfile="updateProfile"
                    @signOut="signOut"
                    @close="ui.popups.editProfile=false"
                />
            </v-card-text>
        </v-card>
    </v-dialog>

    <v-dialog v-model="ui.popups.moreInfo" max-width="1024" persistent>
        <v-card>
            <v-container v-if="signedIn && ui.popups.moreInfo">
                <v-row>
                    <v-col  align="center" align-self="start">
                        <div style="font-weight:bold; line-height: 33px; margin-top: 100px; display: inline-block; text-align: left; font-size: 28pt;">
                            <str index="authenticator.more_info.welcome"/>
                        </div>
                    </v-col>
                    <v-col  align="center" align-self="center">
                        <v-avatar>
                            <v-icon large>
                                mdi-account
                            </v-icon>
                        </v-avatar>
                        <str index="authenticator.more_info.header"/>
                        <MoreInfo @updateMoreInfo="updateMoreInfo" :labels="labels" :language="language" :validations="validations" :rules="rules" v-model="form.update"/>
                    </v-col>
                </v-row>
            </v-container>
            <v-card-actions v-if="debug">
                <v-btn @click="ui.popups.moreInfo = false">Force Close</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-snackbar class='Snackbar_warning' v-model="ui.snackbar.display" centered light>
        <!-- <str class='Snackbar_text' v-if="ui.snackbar.display && ui.snackbar.message" :index="ui.snackbar.message" :data-str="ui.snackbar.message"/> -->
        <str :index="ui.snackbar.message"/>
      <template v-slot:action="{ attrs }">
        <v-btn
          class='Snackbar_button'
          color="black"
          text
          v-bind="attrs"
          @click="ui.snackbar.display = false"
        >
            <str index="buttons.close"/>
        </v-btn>
      </template>
    </v-snackbar>
</div>
</template>

<script>
import cache from '@/plugins/cache.js'
import validations from '@/plugins/validations.js'
import Facebook from './assets/facebook.png'
import Twitter from './assets/twitter.png'
import LinkedIn from './assets/linkedin.png'
import Google from './assets/google.png'
import Amplify, { Auth, Hub } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import awsconfig from '@//aws-exports';
import or from './or'
import UserBadge from './userBadge.vue'
import MoreInfo from './lib/moreInfo.vue'
import EditProfile from './lib/editProfile'

Amplify.configure(awsconfig);

export default {
    props: {
        badge: {
            type: Boolean,
            required: false,
            default: false
        },
        options: {
            type: Object,
            required: false
        },
        buttons: {
            type: Object,
            default: ()=>{
                return {
                    signOut: false
                }
            }
        },
        navDrawerSignOut: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    components: {
        MoreInfo,
        UserBadge,
        EditProfile,
        or
    },
    created: async function(){
        this.validations = new validations.methods(this)
        this.rules = new validations.rules(this)
        this.updateFields()
        await this.findUser()
        this.sessionHandler()
        this.trackInactivity()
        this.$emit('authenticatorReady')

        let remember_me = cache.storage.local.get('remember_me')
        if(remember_me){
            this.ui.remember_me = true
            this.form.signIn.email = remember_me
        }
        Hub.listen('auth', (data) => {
            if(this.debug){
                console.log('Hub response',data)
            }
            const { payload } = data;

            if(payload.event=='signIn'){
                this.findUser()
                this.ui.message = 'sign in OK'
            }else if(payload.event=='signIn_failure'){
                if(payload.data.code=='UserNotConfirmedException'){
                    this.ui.confirmSignUp = true
                    this.ui.error = payload.data.code
                }
                // this.ui.error = {event: payload.event, message:payload.message, data:payload.data}
                // this.ui.error = payload.data
            }

            if(payload.event=='signOut'){
                this.findUser()
                this.ui.message = 'Goodbye'
            }else if(payload.event=='signOut_failure'){
                // this.ui.error = {event: payload.event, message:payload.message, data:payload.data}
                // this.ui.error = payload.data
            }

            if(payload.event=='signUp'){
                this.ui.view = 'SignIn'
                this.ui.confirmSignUp = true
                this.form.signIn.email = this.username
            }else if(payload.event=='signUp_failure'){
                // this.ui.error = {event: payload.event, message:payload.message, data:payload.data}
                // this.ui.error = payload.data
            }

            if(payload.event=='signUp_failure'){
                if(payload.data.code=="UsernameExistsException"){
                    //this.ui.confirmSignUp = true
                    console.log('debug - signUp_failure',payload)
                    //this.resendConfirmationCode()
                    this.ui.snackbar.display = true
                     this.ui.snackbar.message = 'authenticator.messages.account_exists'
                }else{
                    this.ui.snackbar.display = true
                    this.ui.snackbar.message = this.strMap.errors.signUpFailed //this.labels.authenticator.errors.signUpFailed._text[this.language]
                }
            }
        })
    },
    mounted(){
        let self = this
        setTimeout(() => {
            self.$route.name==='SignIn' && self.$refs.sign_in_email.focus()
        }, 200)
    },
    data: function(){
        return {
            config: {
                pinLength: 6
            },
            ui: {
                exposeLabels: false,
                popups: {
                    editProfile: false,
                    moreInfo: false
                },
                changeEmail: false,
                remember_me: false,
                flow: 'password',
                view: this.$route.name=='SignUp' ? 'SignUp' : 'SignIn',
                state: 'ready',
                error: null,
                message: null,
                confirmSignUp: false,
                showPassword: false,
                snackbar: {
                    display: false,
                    message: 'authenticator.messages.account_exists'
                }
            },
            password: {
                new: null,
                confirmation: null
            },
            temp: {
                verifyPassword: null,
                user: null
            },
            form: {
                forgotPassword: {
                    pin: null,
                    password: null
                },
                signIn: {
                    email: null,
                    password: null,
                    pin: null
                },
                signUp: {
                    given_name: null,
                    family_name: null,
                    country: null,
                    prov_state: null,
                    city: null,
                    specialty: null,
                    email: null,
                    password: null,
                    ctc_casl: 'false',
                    terms: false,
                    sample_consent: false,
                },
                changeEmail: {
                    email: null,
                    pin: null
                },
                update: {
                    given_name: null,
                    family_name: null,
                    country:null,
                    prov_state: null,
                    city: null,
                    specialty: null,
                    ctc_casl: 'false'
                },
                verificationCode: null
            },
            rules: null,
            validations: null,
            validForms: {
                signUp: false,
                forgotPassword: false
            },
            session: {
                expires: null,
                time_left: null,
                timer: null,
                inactivity_timer: null
            }
        }
    },
    methods: {
        askMoreInfo: function(){
            let self = this

            if(self.signedIn){
                let attributes = self.user.attributes
                let triggers = ['given_name','family_name']
                // ,'custom:country','custom:prov_state','custom:city','custom:address','custom:postal_zip'
                let result = false
                for(let i=0; i<triggers.length; i++){
                    let trigger = triggers[i]
                    let value = attributes[trigger]
                    let length = value?.length
                    if(!length){
                        result = true
                    }
                }
                self.ui.popups.moreInfo = result===true
            }
        },
        updateFields: function(){
            let fields = [
                {
                    index: 'given_name',
                    target: 'given_name'
                },
                {
                    index: 'family_name',
                    target: 'family_name'
                },
                {
                    index: 'clinic',
                    target: 'custom:clinic'
                },
                {
                    index: 'country',
                    target: 'custom:country'
                },
                {
                    index: 'prov_state',
                    target: 'custom:prov_state'
                },
                {
                    index: 'city',
                    target: 'custom:city'
                },
                {
                    index: 'address',
                    target: 'custom:address'
                },
                {
                    index: 'postal_zip',
                    target: 'custom:postal_zip'
                },
                {
                    index: 'profession',
                    target: 'custom:profession'
                },
                {
                    index: 'specialty',
                    target: 'custom:specialty'
                },
                {
                    index: 'ctc_casl',
                    target: 'custom:ctc_casl'
                }
            ]
            for(let i=0; i<fields.length; i++){
                let field = fields[i]
                this.form.update[field.index] = this.signedIn ? this.$store.getters.user.attributes[field.target] : null
            }
        },
        async signIn(flow='password') {
            let self = this
            let email = this.form.signIn.email
            let username = email


            if(flow=='password'){
                Auth.configure({
                    authenticationFlowType: 'USER_SRP_AUTH'
                })

                let password = this.form.signIn.password
                self.ui.state='sending'
                try {
                    const user = await Auth.signIn(username, password).then(user=>{
                        self.ui.state='ready'
                    })
                } catch (error) {
                    if (error.code!=='UserNotConfirmedException'){
                        self.ui.snackbar.display = true
                        self.ui.snackbar.message = self.strMap.errors.signInFailed //self.labels.authenticator.errors.signInFailed._text[self.language]
                        self.ui.state='error'
                    }
                }
            }

            if(flow=='pin'){
                Auth.configure({
                    authenticationFlowType: 'CUSTOM_AUTH'
                })
                let password = undefined
                self.ui.state='sending'
                let challengeResponse = self.form.signIn.pin
                // await Auth.signIn(username)
                //     .then(user => {
                //         console.log('user response', user)
                //         if (user.challengeName === 'CUSTOM_CHALLENGE') {
                //             // to send the answer of the custom challenge
                //             Auth.sendCustomChallengeAnswer(user, challengeResponse)
                //                 .then(user => console.log(user))
                //                 .catch(err => console.log(err));
                //         } else {
                //             console.log(user);
                //         }
                //     })
                //     .catch(err => console.log(err));
                // self.ui.state='ready'


                try {
                    Auth.sendCustomChallengeAnswer(self.temp.user, challengeResponse)
                    .then((user) => {
                        //console.log('signIn > ok',user)
                    })
                    .catch((err) => {
                        //console.log('signIn > not ok',err)
                        self.ui.state='error'
                    })
                } catch (error) {
                    //console.log('error signing in', error);
                    self.ui.state='error'
                }
            }
        },
        async signUp(){
            let email = this.form.signUp.email
            let username = email
            let given_name = this.form.signUp.given_name
            let family_name= this.form.signUp.family_name
            // let password = this.form.signUp.password
            let password = this.password.new
            let specialty = this.form.signUp.specialty
            let country = this.form.signUp.country
            let prov_state = this.form.signUp.prov_state
            let city = this.form.signUp.city
            let language = this.language
            let sample_consent = this.form.signUp.sample_consent


            let data = {
                username,
                password,
                attributes: {
                    email,
                    given_name,
                    family_name,
                    "custom:country": country,
                    "custom:prov_state": prov_state,
                    "custom:city": city,
                    "custom:specialty": specialty,
                    "custom:language": language
                },
                clientMetadata: {
                    "sample_consent": sample_consent ? "yes" : "no",
                }
            }

            try{
                const {user} = await Auth.signUp(data)
            }catch(err){
                // this.ui.snackbar.display = true
                // this.ui.snackbar.message = this.strMap.errors.signUpFailed //this.labels.authenticator.errors.signUpFailured[this.language]

            }
        },
        async confirmSignUp(){
            let username = this.username
            try {
                let result = await Auth.confirmSignUp(username, this.form.verificationCode);
                this.ui.message = result
                this.ui.confirmSignUp = false
                this.form.verificationCode = null
                this.ui.flow='password'
                this.ui.view='SignIn'
                this.form.signIn.email = username
                // this.form.signIn.password = this.password.new //this.form.signUp.password
                // this.signIn()

            } catch (err) {
                console.log('confirmSignUpResult',err)
                this.ui.error = err
            }
        },
        // async requestEmailChange(){
        //     let self = this
        //     let user = await Auth.currentAuthenticatedUser();
        //     try{
        //         self.temp.user = await Auth.updateUserAttributes(user, {
        //             'email': self.form.changeEmail.email
        //         });
        //     }catch(err){
        //         console.error('Error requestEmailChange',err)
        //     }
        // },
        // async verifyEmailChange(){
        //     let self = this
        //     async function updateHubspot(data){
        //         return new Promise((resolve, reject)=>{
        //             self.sendRequest('patch','/api/profile',data).then((response)=>{
        //                 resolve(response)
        //             },(err)=>{
        //                 reject(err)
        //             })
        //         })
        //     }

        //     self.ui.snackbar.display = true
        //     try{
        //         let result = await Auth.verifyCurrentUserAttributeSubmit('email', self.form.changeEmail.pin);
        //         self.ui.snackbar.message = "authenticator.edit_profile.message.saved_email"
        //         // alert('Please signIn with your new email address')
        //         self.signOut()
        //     }catch(err){
        //         console.error('Error verifyEmailChange',err)
        //         self.ui.snackbar.message = "Error"
        //     }
        // },
        async resendConfirmationCode() {
            this.ui.confirmSignUp = false
            this.ui.error = null
            let username = this.username
            try {
                let result = await Auth.resendSignUp(username);
                this.ui.message = result
                this.ui.snackbar.display = true
                this.ui.snackbar.message = this.strMap.messages.validation_email_sent
            } catch (err) {
                this.ui.snackbar.display = true
                this.ui.snackbar.message = err.code
                this.ui.error = err.code
            }
        },
        async signOut() {
            try {
                let result = await Auth.signOut();
                this.ui.message = result
            } catch (err) {
                this.ui.error = err
            }
        },
        async forgotPassword(operation='generateResetCode'){
            let self = this
            let username = self.form.signIn.email
            let new_password = self.password.new //self.form.signUp.password
            // let code = self.form.signIn.pin.join('')
            let code = self.form.forgotPassword.pin

            self.ui.state='sending'
            if(operation == 'generateResetCode'){
                self.ui.snackbar.display = true
                self.ui.snackbar.message = self.strMap.messages.sending_code //'...sending code...'
                await Auth.forgotPassword(username)
                .then((data) => {
                    setTimeout(()=>{
                        self.ui.snackbar.message = self.strMap.messages.validation_email_sent
                    }, 1160)
                    
                    setTimeout(() => {
                        self.ui.snackbar.message = self.strMap.messages.check_inbox
                    }, 2960)
                })
                .catch((err) => {
                    self.ui.snackbar.message = self.strMap.errors.sending_code //'error sending code'
                    console.log('ERROR forgotPassword > generateResetCode', err)
                    self.ui.state='error'
                });
            }
            if(operation == 'tryResetCode'){
                await Auth.forgotPasswordSubmit(username, code, new_password)
                .then((data) => {
                    console.log('forgotPassword > tryResetCode',data)
                    self.ui.flow = 'password'
                    self.form.signIn.password = self.password.new //self.form.signUp.password
                    self.signIn()
                })
                .catch((err) => {
                    console.log('ERROR forgotPassword > tryResetCode',err)
                    self.ui.state='error'
                    self.ui.snackbar.display = true
                    self.ui.snackbar.message = err.message
                });
            }
            self.ui.state='ready'
        },
        async update(){
            let self = this
            async function updateHubspot(data){
                return new Promise((resolve, reject)=>{
                    self.sendRequest('patch','/api/profile',data).then((response)=>{
                        resolve(response)
                    },(err)=>{
                        reject(err)
                    })
                })
            }

            let given_name = this.form.update.given_name
            let family_name= this.form.update.family_name
            let specialty = this.form.update.specialty
            let country = this.form.update.country
            let prov_state = this.form.update.prov_state
            let city = this.form.update.city
            let ctc_casl = this.form.update.ctc_casl
            let language = this.language


            let data = {
                given_name,
                family_name,
                "custom:country": country,
                "custom:prov_state": prov_state,
                "custom:city": city,
                "custom:specialty": specialty,
                "custom:ctc_casl": ctc_casl,
                "custom:language": language
            }
            console.log('update',data)
            try{
                let user = await Auth.currentAuthenticatedUser();

                try{
                    let result = await Auth.updateUserAttributes(user, data);
                    this.ui.message = result

                    data.email = self.$store.getters.user.attributes.email
                    await updateHubspot(data)
                }catch(err){
                    this.ui.error = err
                }
            }catch(err){
                this.ui.error = err
            }
        },
        async updateProfile(profile){
            let self = this
            async function updateHubspot(data){
                return new Promise((resolve, reject)=>{
                    self.sendRequest('patch','/api/profile',data).then((response)=>{
                        resolve(response)
                    },(err)=>{
                        reject(err)
                    })
                })
            }


            let given_name = this.form.update.given_name
            let family_name= this.form.update.family_name
            let specialty = this.form.update.specialty == undefined || this.form.update.specialty==null ? '' : this.form.update.specialty
            let clinic = this.form.update.clinic == undefined || this.form.update.clinic==null ? '' : this.form.update.clinic
            let country = this.form.update.country == undefined || this.form.update.country==null ? '' : this.form.update.country
            let prov_state = this.form.update.prov_state == undefined || this.form.update.prov_state==null ? '' : this.form.update.prov_state
            let city = this.form.update.city == undefined || this.form.update.city==null ? '' : this.form.update.city
            let postal_zip = this.form.update.postal_zip == undefined || this.form.update.postal_zip==null ? '' : this.form.update.postal_zip
            let address = this.form.update.address == undefined || this.form.update.address==null ? '' : this.form.update.address
            let profession = this.form.update.profession == undefined || this.form.update.profession==null ? '' : this.form.update.profession
            let ctc_casl = this.form.update.ctc_casl == undefined || this.form.update.ctc_casl==null ? '' : this.form.update.ctc_casl
            let language = this.language


            let data = {
                given_name,
                family_name,
                "custom:clinic": clinic,
                "custom:country": country,
                "custom:prov_state": prov_state,
                "custom:city": city,
                "custom:postal_zip": postal_zip,
                "custom:address": address,
                "custom:profession": profession,
                "custom:specialty": specialty,
                "custom:ctc_casl": ctc_casl,
                "custom:language": language
            }


            try{
                let user = await Auth.currentAuthenticatedUser();

                try{
                    let updateCognitoResult = await Auth.updateUserAttributes(user, data)
                    data.email = self.$store.getters.user.attributes.email
                    let updateHubSpotResult = await updateHubspot(data)
                    this.ui.snackbar.message = 'authenticator.messages.profile_update_success'
                    this.ui.popups.editProfile = false
                    window.location.reload()
                }catch(err){
                    this.ui.error = err
                }
            }catch(err){
                this.ui.error = err
            }

            this.ui.snackbar.display = true

        },
        async updateMoreInfo(profile){
            await this.updateProfile(profile)
            this.ui.popups.moreInfo = false
            window.location.reload()
        },
        sessionHandler: async function(){
            let self = this
            let user = self.$store.getters.user
            clearTimeout(self.session.timer)
            if(self.signedIn && user){
                self.session.expires = new Date(user.signInUserSession.accessToken.payload.exp*1000)

                let t = new Date()
                let time_left = (self.session.expires.getTime() - t.getTime()) / 1000
                self.session.time_left = time_left

                if(time_left<0){
                    if(self.$store.getters.inactivity>60){
                        console.log('Signing out')
                        await Auth.signOut()
                    }else{
                        console.log('Restarting session')
                        await self.findUser()
                    }
                }


                self.session.timer = setTimeout(function(){
                    self.sessionHandler()
                },(1000))
            }else{
                self.session.expires = null
            }
        },
        trackInactivity: function(){
            let self = this
            self.$store.commit('inactivity',(self.$store.getters.inactivity+1))
            clearTimeout(self.session.inactivity_timer)
            self.session.inactivity_timer = setTimeout(function(){
                if(self.signedIn){
                    self.trackInactivity()
                }
            },1000)
        },
        async findUser(){
            let self = this
            let user
            try{
                user = await Auth.currentAuthenticatedUser()
                this.$store.commit('user',user)
            }catch(err){
                this.$store.commit('user',undefined)
                // this.$router.push({name:'SignIn'})
            }

            if(user){
                setTimeout(function(){
                    self.askMoreInfo()
                },200)
            }
        },
        async federation(idp){
            try{
                let result = Auth.federatedSignIn({provider: CognitoHostedUIIdentityProvider[idp]})
                this.ui.message = result
            }catch(err){
                this.ui.error = err
            }

        },
        // verifyPassword: function(v){
        //     let results = {
        //         areStrings: (typeof v == 'string' && typeof this.form.signUp.password=='string'),
        //         vType: typeof v,
        //         pType: typeof this.form.signUp.password,
        //         matches: (v == this.form.signUp.password)
        //     }

        //     console.log('verifyPassword',{v, results})
        //     return results.areStrings && results.matches
        // },
        requestPIN: async function(){
            let self = this
            let email = this.form.signIn.email
            let username = email

            if(this.validations.validEmail(this.form.signIn.email)){

                Auth.configure({
                    authenticationFlowType: 'CUSTOM_AUTH'
                })
                self.ui.state='sending'
                try {
                    await Auth.signIn(username).then(user=>{
                        //console.log('sendOTP > ok',user)
                        self.temp.user = user
                        
                        self.ui.snackbar.message = self.strMap.messages.sending_code
                        self.ui.snackbar.display = true

                        setTimeout(() => {
                            self.ui.snackbar.message = self.strMap.messages.check_inbox
                        }, 1960)                        
                    }).catch(function(err){
                        console.log('sendOTP > not ok', err)
                        self.ui.snackbar.message = self.strMap.errors.check_inbox
                    });
                    self.ui.state='ready'
                    self.ui.flow='pin'
                } catch (error) {
                    console.log('error signing in', error);
                    self.ui.state='error'
                }

            }

        },
        rememberMe: function(){
            let checkbox = this.ui.remember_me

            if(checkbox){
                cache.storage.local.set('remember_me', this.form.signIn.email)
            }else{
                cache.storage.local.clear('remember_me')
            }
        },
        contains: function(sourceArray, targetArray){
            if( (typeof sourceArray=='object' && sourceArray!=null) && (typeof targetArray=='object' && targetArray!=null) ){
                let result = sourceArray.filter((value)=>{return targetArray.includes(value)})
                return result.length>0
            }else{
                return false
            }
        },
        exposeLabels: function(){
            this.$store.commit('exposeLabels',this.ui.exposeLabels)
        },
        handleLinkCheckboxClick(event) {
            // Ensure only the <a> tag click is handled
            if (event.target.tagName === 'A') {
                event.stopPropagation(); // Stop checkbox from toggling
            }
        },            
    },
    computed: {
        strMap: function(){
            return {
                messages: {
                    sending_code: 'authenticator.messages.sending_code',
                    check_inbox: 'authenticator.messages.check_inbox',
                    validation_email_sent: 'authenticator.messages.validation_email_sent'
                },
                errors: {
                    signInFailed: 'authenticator.errors.signInFailed',
                    signUpFailed: 'authenticator.errors.signUpFailed',
                    sending_code: 'authenticator.errors.sending_code'

                }
            }
        },
        debug: function(){
            return this.$store.getters.debug
        },
        labels: function(){
            return this.$store.getters.labels
        },
        language: function(){
            return this.$store.getters.language
        },
        allowPIN: function(){
            let pin = this.form.signIn.pin
            return pin!=null && pin.length == this.config.pinLength
            // let pin = this.form.signIn.pin
            // let hasNull = pin.includes(null)
            // let hasBlank = pin.includes('')

            // return !hasNull && !hasBlank
        },
        allowChangePassword: function(){
            let pin = this.form.forgotPassword.pin
            return pin!=null && pin.length == this.config.pinLength && this.validations.validEmail(this.form.signIn.email)

        },
        allowForgotPasswordResetAttempt: function(){
            let pin = this.form.forgotPassword.pin
            return pin!=null && pin.length == this.config.pinLength && this.validations.validEmail(this.form.signIn.email) && this.validations.verifyPassword(this.temp.verifyPassword)

        },
        allowSignIn: function(){
            return (this.form.signIn.email!=null && this.form.signIn.email.length>=5) && this.form.signIn.password!=null && this.form.signIn.password.length>=8 && this.ui.state!='sending'
        },
        // allowSignUp: function(){
        //     let form = this.form.signUp
        //     return (form.email!=null && form.email.length>=6) && (form.password!=null && form.password.length>=8) && (form.password == this.temp.verifyPassword) && form.terms
        //         // (form.given_name!=null && form.given_name.length>=2) &&
        //         // (form.family_name!=null && form.family_name.length>=2) &&
        //         // (form.email!=null && form.email.length>=6) &&
        //         // (form.password!=null && form.password.length>=8) &&
        //         // (form.country!=null && form.country.length>=6) &&
        //         // (form.prov_state!=null && form.prov_state.length>=6) &&
        //         // (form.city!=null && form.city.length>=6) &&
        //         // (form.specialty!=null && form.specialty.length>=6)
        // },
        allowUpdate: function(){
            let form = this.form.update
            return (form.given_name!=null && form.given_name.length>=2) &&
                (form.family_name!=null && form.family_name.length>=2) &&
                (form.country!=null && form.country.length>=6) &&
                (form.prov_state!=null && form.prov_state.length>=6) &&
                (form.city!=null && form.city.length>=6) &&
                (form.specialty!=null && form.specialty.length>=6)
        },
        signedIn: function(){
            return this.$store.getters.signedIn
        },
        username: function(){
            return this.ui.view=='SignIn' ? this.form.signIn.email : this.form.signUp.email
        },
        images: function(){
            return {
                Facebook,
                Twitter,
                Google,
                LinkedIn
            }
        },
        user: function(){
            let user
            if(this.signedIn){
                user = {
                    attributes: this.$store.getters.user.attributes,
                    groups: this.$store.getters.user.signInUserSession.accessToken.payload['cognito:groups']
                }
            }

            return user
        },
        kenvuePrivacyPolicyLink: function(){
            return this.language==="fr-ca" ? "https://www.kenvue.com/privacy-policy/canada-fr" : "https://www.kenvue.com/privacy-policy/canada/en"
        },        
    },
    watch: {
        signedIn: function(){
            let self = this
            self.updateFields()

            setTimeout(function(){
                if(!self.signedIn){
                    self.$router.push('/login')
                }else{
                    self.sessionHandler()
                    self.trackInactivity()
                    let pathAfterLogin = cache.storage.local.get('pathAfterLogin')
                    if(pathAfterLogin){
                        cache.storage.local.clear('pathAfterLogin')
                        self.$router.push(pathAfterLogin)
                    }
                }
            },200)
        },
        language: function(after, before){
            if (this.$refs.tabs!==undefined){
                this.$refs.tabs.callSlider()
            }
        },
        navDrawerSignOut: async function(after, before) {
            if (after===true && before===false){
                await this.signOut()
                this.$emit('signOutComplete')
            }
        }
    }
}
</script>

<style scoped lang="scss">
.theme-secondary{
    color: $secondary;
}
.theme-primary{
    color: $primary;
}

.tabContainer {
    margin-top: 8px;
}

.v-tab{
    font-size: 1.2em;
}
.v-tab--active{
    color: $primary-light-1 !important;
}
.tab-slider{
    background-color: $primary-light-1 !important;

}
.signIn-view{
    text-align: center;
    min-height: 600px;
}

.signIn-view button.theme {
    width: 300px !important;
}

.v-btn.primary{
    color: white !important;
    background-color: $primary-light-1 !important;
}
</style>

<style>
.v-tabs-slider-wrapper{
    height: 4px !important;
}
/* we will explain what these classes do next! */
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}



.pin-input{
    width: 50px;
    display: inline-block !important;
    margin: 3px !important;
    font-size: 25px !important;
}

.pin-input input{
    text-align: center;
}

.Snackbar_warning > .v-snack__wrapper {
    min-height: 112px;
    /* red bar */
    border-top: 16px solid rgb(255,82,82) !important;
}

.Snackbar_warning > div {
    display: block;
    padding: 8px 24px 24px;
}

.Snackbar_warning .Snackbar_text {
    display: block;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
}

.Snackbar_warning .Snackbar_button {
    position: absolute;
    bottom: 8px;
    right: 8px;
}

.chkbx-wrapper .v-input--selection-controls .v-input__slot {
    align-items: flex-start;
}

// RESPONSIVE RULES TAB UI ON SMALL MOBILE SCREEN SIZE
<style lang='scss'>
@media(max-width:575px){
    .tabContainer {
        height: auto !important;

        .v-toolbar__content {
            height: auto !important;

            .v-slide-group {
                height: auto !important;
            }

            .v-slide-group__wrapper {

                .v-tab {
                    min-width: 280px;
                }

                > div {
                    display: block;

                    a {
                        margin: 16px auto;

                        &.v-tab--active {
                            background-color: #EAF3F6;
                        }
                    }
                }
            }
        }
    }
}
</style>
