<template>
<div class="speaker" :class="[{'enrolled':enrolled}]">
    <v-avatar size="90" class="speaker-avatar">
        <v-icon v-if="speaker.image.type=='icon'" large>{{speaker.image.value}}</v-icon>
        <img v-if="speaker.image.type=='img'" :src="images.speakers[speaker.image.value]"/>
    </v-avatar>
    
    <div style="margin: 8px;">
        <div class="speaker-name">
            {{speaker.given_name}} {{speaker.family_name}}
        </div>
        <div class="speaker-credentials">
            {{speaker.credentials[language]}}
        </div>
    </div>
</div>
</template>

<script>
import speaker_1 from './assets/speaker_1.png'
import speaker_2 from './assets/speaker_2.png'
import speaker_3 from './assets/speaker_3.png'
export default {
    props: {
        enrolled: {
            type: Boolean,
            required: false,
            default: false
        },
        speaker: {
            type: Object,
            required: true
        },
        language: {
            type: String,
            required: true
        }
    },
    computed: {
        images: function(){
            return {
                speakers: {
                    speaker_1,
                    speaker_2,
                    speaker_3,
                }
            }
        }
    }
}
</script>

<style scoped lang="scss">
.speaker{
    color: $primary;
    font-weight: bold;
    line-height: 18px;
}
    .speaker.enrolled{
        color: $secondary;
    }


.speaker-avatar{
    border: 5px solid $primary;
}
    .enrolled .speaker-avatar {
        border-color: $secondary;
    }


</style>